import Mock from "../mock"

const database = {
  information: {
    name: "Gabriel Alfonso",
    aboutContent:
      "I am a web app and mobile app developer. I can produce clean code and a design tailored to the client. I also make websites more interactive with intuitive UX and sharp web animations.",
    age: 24,
    phone: "786-362-2052",
    nationality: "American",
    language: "English, Spanish",
    email: "gabrielalfonso99@gmail.com",
    address: "Miami, Florida, United States",
    freelanceStatus: "Available",
    socialLinks: {
      facebook: "",
      twitter: "",
      pinterest: "",
      behance: "",
      linkedin: "https://www.linkedin.com/in/gabriel-alfonso/",
      dribbble: "",
      github: "https://github.com/galfonso99",
    },
    brandImage: "/images/brand-image.jpg",
    aboutImage: "/images/about-image.jpg",
    aboutImageLg: "/images/about-image-lg.jpg",
    resumefile: "/files/Resume.pdf",
  },
  services: [
    {
      title: "Web Design",
      icon: "color-pallet",
      details: "",
    },
    {
      title: "Web Development",
      icon: "code",
      details: "",
    },
    {
      title: "Mobile Development",
      icon: "mobile",
      details: "",
    },
  ],
  skills: [
    {
      title: "React",
      value: 95,
    },
    {
      title: "React Native",
      value: 95,
    },
    {
      title: "TypeScript",
      value: 90,
    },
    {
      title: "JavaScript",
      value: 90,
    },
    {
      title: "Rust",
      value: 85,
    },
    {
      title: "Golang",
      value: 80,
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2021 - Present",
        position: "Web App Developer",
        company: "Arcadia Appliances LLC.",
        details:
          "Designed and developed a website that represents the brand of the company using React for the frontend and NodeJS for the backend.",
      },
      {
        id: 2,
        year: "2020 - 2020",
        position: "Mobile App Developer",
        company: "Florida International University",
        details:
          "Developed a reactive mobile app using React Native to maintain a single codebase for IOS and Android",
      },
      {
        id: 3,
        year: "2019 - 2020",
        position: "Learning Assistant",
        company: "Florida International University",
        details: "Assisted in the grading of class assignments",
      },
    ],
    educationExperience: [
      {
        id: 1,
        year: "2017 - 2020",
        graduation: "BS-Computer Science",
        university: "Florida International University",
        details:
          "In my time at FIU, I attended several Hackathons and participated in ACM and UPE organized activities.",
      },
      {
        id: 2,
        year: "2015 - 2017",
        graduation: "College Associates",
        university: "Miami-Dade College",
        details:
          "For the last two years of High School I enrolled in Dual Enrollment and was able to graduate from MDC around the same time I graduated from High School.",
      },
      {
        id: 3,
        year: "2015 - 2017",
        graduation: "High School Graduation",
        university: "School for Advanced Studies",
        details: "Dual Enrollment High School",
      },
    ],
  },
  contactInfo: {
    phoneNumbers: ["786-362-2052"],
    emailAddress: ["gabrielalfonso99@gmail.com"],
    address: "Miami, Florida, United States",
  },
}

Mock.onGet("/api/information").reply((config) => {
  const response = database.information
  return [200, response]
})

Mock.onGet("/api/services").reply((config) => {
  const response = database.services
  return [200, response]
})

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews
  return [200, response]
})

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills
  return [200, response]
})

Mock.onGet("/api/portfolios").reply((config) => {
  const response = database.portfolios
  return [200, response]
})

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience
  return [200, response]
})

Mock.onGet("/api/blog").reply((config) => {
  const response = database.blogs
  return [200, response]
})

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo
  return [200, response]
})
